import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

import { OPEN_HAMBURGER_MENU, OPEN_SEARCHBAR } from "../../../store/actions";
import { MenuItem } from "../../menu-item";
import HamburgerMenu from "../../../atoms/hamburger-menu";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";

import "./mobile-header.m.css";
import { Link } from "@quintype/components";
import MyAccount from "../../../atoms/my-account";
import HeaderNotification from "../../../atoms/header-notification";
import HamburgerAppStore from "../../../atoms/hamburger-app-store";
import HamburgerGooglePlay from "../../../atoms/hamburger-google-play";
import Modal from "../../../atoms/modal";
import MyAccountButton from "../../../atoms/my-account-button";
import HamuburgerEPaperButton from "../../../atoms/hamburger-ePaper-button";
import HamburgerMyAccountButton from "../../../atoms/hamburger-myAccount-button";
import HamburgerLoginButton from "../../../atoms/login-button";
import useDevice from "../../../helper/custom-hook/useDevice";

const MobileHeader = () => {
  const getState = useSelector((state) => state);
  const dispatch = useDispatch();
  const isMobile = useDevice();
  const [navSearchText, setNavSearchText] = useState("");

  const mobile = get(getState, ["qt", "data", "navigationMenu", "primaryMenus"], []);
  const mobileSecondaryMenu = get(getState, ["qt", "data", "navigationMenu", "secondaryMenus"], []);
  const combinedMenus = mobile.concat(mobileSecondaryMenu);
  const isHamburgerMenuOpen = get(getState, ["isHamburgerMenuOpen"], false);
  const [changeTextColor, setChangeTextColor] = useState(false);
  const [showMenuSection, setShowMenuSection] = useState(false);
  const [showModal, setShowModal] = useState("");
  const [redirectURL, setRedirectUrl] = useState("");
  const ssoEndpoint = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "sso_endpoint"], null)
  );
  const isUserLogin = useSelector((state) => get(state, ["isLogin"], false));

  useEffect(() => {
    const isProfileOpen = window.localStorage.getItem("isProfileOpen");
    const stickyBanner = document.querySelector("#sticky-banner-id");
    setShowModal(isProfileOpen);
    if (isMobile == true) {
      if (isProfileOpen == "true") {
        stickyBanner.style.display = "none";
      } else {
        stickyBanner.style.display = "block";
      }
    }
  }, [showModal, isMobile]);

  const openModal = () => {
    setShowModal("true");
    localStorage.setItem("isProfileOpen", "true");
  };
  const closeModal = () => {
    setShowModal("false");
    localStorage.setItem("isProfileOpen", "false");
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isHamburgerMenuOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    }
  }, [isHamburgerMenuOpen]);

  useEffect(() => {
    setRedirectUrl(window.location.href);
  }, []);

  const signinHandler = () => {
    console.log("CLICK");
    if (window) {
      window.dataLayer.push({
        event: "SSO_Page_Open"
      });
    }
    window.location.href = `${ssoEndpoint}/auth?redirectUrl=${redirectURL}`;
  };

  const toggleHandler = () => {
    dispatch({
      type: OPEN_HAMBURGER_MENU,
      isHamburgerMenuOpen: !isHamburgerMenuOpen
    });

    dispatch({
      type: OPEN_SEARCHBAR,
      isSearchBarOpen: false
    });
  };
  const toggleMenu = () => {
    toggleHandler();
  };

  const changeTextColorHandler = (idx) => {
    toggleHandler();
  };

  const showMenuSectionHandler = (idx) => {
    setShowMenuSection(showMenuSection === idx ? undefined : idx);
  };

  const getSecondNavbarMenu = () => {
    return (
      <ul styleName="navbar">
        {mobile.length > 0 &&
          mobile.map((item, index) => {
            const isColor = item?.data?.color.toLowerCase() !== "#ffffff";
            return (
              <li key={index} styleName="second-nav">
                <MenuItem
                  changeTextColor={isColor}
                  item={item}
                  menuStyle="menu-link"
                  toggleHandler={() =>
                    dispatch({
                      type: OPEN_SEARCHBAR,
                      isSearchBarOpen: false
                    })
                  }
                />
              </li>
            );
          })}
      </ul>
    );
  };

  const getDropdownList = () => {
    if (!isHamburgerMenuOpen) {
      return null;
    }
    const navigateToPage = (url) => global.app.navigateToPage(dispatch, url);

    const handleSearchOnEnter = (e) => {
      if (e.key === "Enter") {
        navigateToPage(`/search?q=${navSearchText}`);
        toggleHandler();
      }
    };
    return (
      <>
        <div styleName="overlay" onClick={() => toggleHandler()}></div>
        <div styleName="custom-list">
          <div styleName="mobnav-top-wrapper">
            <div styleName="hamburger-head">
              <div onClick={() => toggleHandler()}>
                <Link href="/">
                  <img src="/icons/lstlogo.png" styleName="pv-logo" />
                </Link>
              </div>
              <div styleName="hamburger-close">
                <HamburgerMenu
                  isMobileHamburger={true}
                  onMenuToggle={() => toggleHandler()}
                  isMegaMenuOpen={isHamburgerMenuOpen}
                />
              </div>
            </div>
            <div styleName="hamburger-search-box">
              <input
                type="text"
                placeholder="ಹುಡುಕಿ"
                value={navSearchText}
                onChange={(e) => setNavSearchText(e.target.value)}
                onKeyDown={handleSearchOnEnter}
              />
              <Link href={`/search?q=${navSearchText}`}>
                <button
                  aria-label="search-button"
                  onClick={toggleHandler}
                  styleName="search-box"
                  style={{ margin: "12px" }}
                  type="submit"
                  key="2"
                >
                  <SvgIconHandler type="search" />
                </button>
              </Link>
            </div>
            <div styleName="buttons-container">
              <HamuburgerEPaperButton />
              {!isUserLogin && isUserLogin !== undefined && isUserLogin !== null ? (
                <HamburgerLoginButton onClick={signinHandler} />
              ) : (
                <HamburgerMyAccountButton onAccountHandler={openModal} />
              )}
              <Modal onClose={closeModal} isOpen={showModal == "true" ? true : false}>
                <MyAccount closeModal={closeModal} />
              </Modal>
            </div>
            {/* <div styleName="buttons-container">
              <a
                href="https://apps.apple.com/in/app/prajavani-kannada-news-app/id1535764933"
                target="_blank"
                rel="noreferrer"
              >
                <HamburgerAppStore width="127" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.tpml.pv&hl=en_IN&gl=US&pli=1"
                target="_blank"
                rel="noreferrer"
              >
                <HamburgerGooglePlay width="127" />
              </a>
            </div> */}
          </div>
          <div styleName="menulist">
            {combinedMenus.length > 0 &&
              combinedMenus.map((item, index) => (
                <>
                  <li key={index} styleName="dropdown menu-main">
                    <MenuItem
                      changeTextWeight={item?.data?.color.toLowerCase() !== "#ffffff"}
                      menuStyle="menu-link"
                      item={item}
                      toggleHandler={() => toggleHandler()}
                    />
                    {item.children?.length > 0 && (
                      <button
                        aria-label="circle-down-arrow-button"
                        styleName={showMenuSection === index ? "down-arrow-rotate down-arrow" : "down-arrow"}
                        type="submit"
                        key="2"
                        onClick={() => showMenuSectionHandler(index)}
                      >
                        <SvgIconHandler type="circledownarrow" />
                      </button>
                    )}
                  </li>
                  {showMenuSection === index && (
                    <div styleName="submenu">
                      {item.children.length > 0 && (
                        <div styleName="ddsection-submenu">
                          <div styleName="ddsec1-submenu">
                            {item.children.slice(0, 11).map((menuItem, index) => (
                              <Link
                                key={index}
                                externalLink={menuItem.isExternalLink}
                                href={menuItem && menuItem?.url}
                                onClick={toggleHandler}
                              >
                                <li
                                  style={{
                                    fontWeight: `${menuItem?.data?.color.toLowerCase() !== "#ffffff" ? "bold" : ""}`
                                  }}
                                  styleName={changeTextColor === index ? "navbar-li_active" : ""}
                                  onClick={() => changeTextColorHandler(index)}
                                >
                                  {menuItem.title}
                                </li>
                              </Link>
                            ))}
                          </div>

                          {item.children.length >= 11 && (
                            <div styleName="ddsec2-submenu">
                              {item.children.slice(11, 22).map((menuItem, index) => (
                                <Link
                                  key={index}
                                  externalLink={menuItem.isExternalLink}
                                  href={menuItem && menuItem?.url}
                                  onClick={toggleHandler}
                                >
                                  <li
                                    style={{
                                      fontWeight: `${menuItem?.data?.color.toLowerCase() !== "#ffffff" ? "bold" : ""}`
                                    }}
                                    styleName={changeTextColor === index ? "mobnav-li_active" : ""}
                                    onClick={() => changeTextColorHandler(index)}
                                  >
                                    {menuItem.title}
                                  </li>
                                </Link>
                              ))}
                            </div>
                          )}
                          {item.children.length >= 22 && (
                            <div styleName="ddsec3-submenu">
                              {item.children.slice(22, 32).map((menuItem, index) => (
                                <Link
                                  key={index}
                                  externalLink={menuItem.isExternalLink}
                                  href={menuItem && menuItem?.url}
                                  onClick={toggleHandler}
                                >
                                  <li
                                    style={{
                                      fontWeight: `${menuItem?.data?.color.toLowerCase() !== "#ffffff" ? "bold" : ""}`
                                    }}
                                    styleName={changeTextColor === index ? "mobnav-li_active" : ""}
                                    onClick={() => changeTextColorHandler(index)}
                                  >
                                    {menuItem.title}
                                  </li>
                                </Link>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </>
              ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <div id="mobile-header" styleName="mobile-stickybar">
      <div styleName="mobile-logo">
        <Link href="/">
          <img src="/icons/lstlogo.png" styleName="pv-logo" />
        </Link>
        {/* <div styleName="notification-wrapper">
          <HeaderNotification />
        </div> */}
      </div>
      <header id="nav-bar" className="sticky-header">
        <div id="sticky-navbar" styleName="main-wrapper">
          <nav styleName="wrapper">
            <div>{getSecondNavbarMenu()}</div>
            <div styleName="rhs-wrapper">
              <div styleName="icons-wrapper">
                {/* <Link href="/search" aria-label="search-button" styleName="search-box" type="submit" key="2">
                  <SvgIconHandler
                    type="whitesearch"
                    className="mobile-search"
                    iconStyle={{ color: "#fff" }}
                    height="24px"
                    width="24px"
                  />
                </Link> */}
                {!isUserLogin && isUserLogin !== undefined && isUserLogin !== null ? (
                  <button onClick={signinHandler} styleName="login-btn">
                    <SvgIconHandler type="myaccount_white" width="24" height="24" />
                  </button>
                ) : (
                  <MyAccountButton onAccountHandler={openModal} />
                )}
                <Modal onClose={closeModal} isOpen={showModal == "true" ? true : false}>
                  <MyAccount closeModal={closeModal} />
                </Modal>
              </div>
              {mobile.length > 0 ? (
                <div>
                  <button styleName="header-hamburger-svg" onClick={toggleMenu}>
                    <SvgIconHandler type="hamburgericonwhite" width="28" height="19" viewBox="0 0 28 19" />
                  </button>
                  <HamburgerMenu isMobileHamburger={true} onMenuToggle={toggleMenu} isMegaMenuOpen={false} />
                  {getDropdownList()}
                </div>
              ) : (
                <div />
              )}
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};

export { MobileHeader };
